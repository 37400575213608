<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3 bg-white rounded-lg shadow-lg h-max">
            <div class="p-5">
                <div>
                    <label class="text-gray-400">
                        服務商 手機
                        <el-input v-model="filterOpts.phone" size="small" clearable> </el-input>
                    </label>
                </div>
                <div>
                    <label class="text-gray-400">
                        列帳月份
                        <el-select v-model="filterOpts.year" size="small" class="w-full">
                            <el-option v-for="(status, index) in yearOptions" :key="index" :label="status.label" :value="status.value">
                                {{ status.label }}
                            </el-option>
                        </el-select>
                        <el-select v-model="filterOpts.month" size="small" class="w-full">
                            <el-option v-for="(status, index) in monthOptions" :key="index" :label="status.label" :value="status.value">
                                {{ status.label }}
                            </el-option>
                        </el-select>
                    </label>
                </div>
                <div class="col-span-2 flex items-end mt-5">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="col-span-9 rounded-lg relative">
            <div>
                <LoadingComponent :isLoading="loading" />
                <div class="shadow-lg rounded-lg bg-white flex items-center p-5 mb-3">
                    <div class="w-[100%] grid grid-cols-12 gap-4">
                        <!-- <div class="col-span-5 flex flex-col items-center">
                            <div>總收益</div>
                            <div class="font-medium md:text-2xl text-base mt-2">$ 80,072</div>
                        </div> -->

                        <div class="col-span-10 flex flex-col items-center">
                            <div>服務商數</div>
                            <div class="font-medium md:text-2xl text-base mt-2">{{ providerCount }} 位</div>
                        </div>

                        <div class="col-span-2 flex items-end">
                            <button class="black-btn-outline md:text-sm text-xs w-[100px] h-[40px] flex-1" @click="goBack">返回列表</button>
                        </div>
                    </div>
                </div>
                <div class="overflow-x-auto bg-white">
                    <table class="myTable">
                        <TableHead ref="TableHead" :labels="tableLabels" @changeSort="changeSort" />
                        <tbody>
                            <tr v-for="item in datas" :key="item.id" class="text-center duration-200 transition">
                                <td>{{ item.user.name }}</td>
                                <td>{{ item.month }}</td>
                                <!-- <td>$ 0</td> -->
                                <td>$ {{ item.revenue }}</td>
                                <td>$ {{ item.commission }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="justify-center mt-3 flex mb-3">
                        <MyPagination
                            :paginationData="paginationData"
                            :currentPage="currentPage"
                            @onPageSizeChange="pageSizeChange"
                            @onCurrentPageChange="currentPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// init search Filter options
const defaultFilterOpts = {
    year: "", // created_at or started_at or gross_price
    month: "", // asc or desc
    phone: "",
};

import moment from "moment";

export default {
    name: "ProviderList",
    components: {
        LoadingComponent,
        MyPagination,
        TableHead,
    },
    props: {
        // 是否為服務商
        isProvider: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        // table 標籤
        tableLabels() {
            const tableLabels = [
                {
                    label: "暱稱",
                },
                {
                    label: "月結款項",
                },
                // {
                //     label: "訂單總額",
                // },
                {
                    label: "服務商收益",
                },
                {
                    label: "介紹人收益",
                },
            ];
            return tableLabels;
        },
        // 統整 getlist api filters
        filterData() {
            const filter = {};
            for (const key in this.filterOpts) {
                if (this.filterOpts[key]) {
                    filter[key] = this.filterOpts[key];
                }
            }
            return {
                ...filter,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
        },
        // 活動選項 options
        yearOptions() {
            const year = moment().format("YYYY");
            const result = [];
            for (let i = 0; i < 10; i++) {
                result.push({
                    label: year - i,
                    value: year - i,
                });
            }
            return result;
        },
        // 活動選項 options
        monthOptions() {
            const result = [
                {
                    label: "一月",
                    value: "01",
                },
                {
                    label: "二月",
                    value: "02",
                },
                {
                    label: "三月",
                    value: "03",
                },
                {
                    label: "四月",
                    value: "04",
                },
                {
                    label: "五月",
                    value: "05",
                },
                {
                    label: "六月",
                    value: "06",
                },
                {
                    label: "七月",
                    value: "07",
                },
                {
                    label: "八月",
                    value: "08",
                },
                {
                    label: "九月",
                    value: "09",
                },
                {
                    label: "十月",
                    value: "10",
                },
                {
                    label: "十一月",
                    value: "11",
                },
                {
                    label: "十二月",
                    value: "12",
                },
            ];

            return result;
        },
    },
    data() {
        return {
            loading: false,
            providerCount: 0,
            datas: [],
            id: "", // 服務商id
            info: {},
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts },
        };
    },
    methods: {
        async getBrokerData() {
            await this.$api.GetSingleBrokerApi(this.id).then((res) => {
                this.info = { ...res.data };
            });
        },
        // 讀取 list api
        async getList() {
            const { brokerId } = this.$route.params;
            this.loading = true;
            const { status, data } = await this.$api
                .SearchTransactionsByMonth(brokerId, this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.providerCount = data.total;
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        // 搜尋
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        // 改變排序
        changeSort(val) {
            this.filterOpts.sort_by = val.sortBy;
            this.filterOpts.sort_type = val.orderBy;
            this.getList();
        },
        // 重置
        reset() {
            this.filterOpts = { ...defaultFilterOpts, year: this.$route.params.year, month: this.$route.params.month };
            this.$refs.TableHead.reset();
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        // 切頁相關
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        goBack() {
            this.$router.push({ path: `/user_list/edit_introducer/${this.$route.params.brokerId}/paymentInfo` });
        },
    },
    async mounted() {
        const { brokerId } = this.$route.params;
        this.id = brokerId;
        const { year, month } = this.$route.params;
        this.filterOpts = {
            year,
            month,
        };
        this.getList();
        await this.getBrokerData();
        this.$store.commit("breadcrumbStore/setCrumbList", [
            {
                meta: {
                    text: "用戶管理",
                },
            },
            {
                name: "introducer_list",
                meta: {
                    text: "介紹人管理",
                },
                path: "#/user_list/introducer_list",
            },
            {
                meta: {
                    text: this.info.name,
                },
            },
            {
                meta: {
                    text: "款項資訊",
                },
                usePath: true,
                path: `#/user_list/edit_introducer/${this.$route.params.brokerId}/paymentInfo`,
            },
            {
                name: "payment_detail_list",
                meta: {
                    text: "款項資訊明細",
                },
            },
        ]);
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}

::v-deep .el-message-box__message {
    word-wrap: break-word !important;
}
</style>
